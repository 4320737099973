@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

@layer utilities {
  .tw-hidden {
    @apply hidden;
  }

  /* .webkit- {
      ::-webkit-scrollbar {
          width: 0;
          / * Remove scrollbar space * /
          background: transparent;
          / * Optional: just make scrollbar invisible * /
        }
  } */
}